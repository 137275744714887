import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Home from './Landing';
import PrivacyPolicy from './PrivacyPolicy';
import TermsOfService from './TermsOfService';
import FourZeroFour from './FourZeroFour';

const Router = () => (
    <BrowserRouter>
        <Switch>
            <Route exact path="/" component={Home}/>
            <Route path="/home" component={Home}/>
            <Route path="/privacypolicy" component={PrivacyPolicy}/>
            <Route path="/termsofservice" component={TermsOfService}/>
            <Route component={FourZeroFour}/>
        </Switch>
    </BrowserRouter>
);

export default Router;