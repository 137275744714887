import React from 'react';
import 'antd/dist/antd.css';
import '../styles/style.css'
import NavigationMenu from './NavigationMenu.js';
import ScrollToTopOnMount from './ScrollToTopOnMount.js'

class TermsOfService extends React.Component {

    state = {
        current: 'termsofservice',
    }
    
    handleCurrentPageState = (e) => {
        if (e.key === this.state.current) {
            return;
        }

        this.props.history.push(`${e.key}`)
    }

    render() {
        return (
            <div>
                <ScrollToTopOnMount/>
                <header className="termsOfService">
                    <h1>Terms Of Service</h1>
                    <h5 style={{ padding: "5vh 2.5em", textAlign: "left" }}>
                    Please read this Agreement carefully before accessing or using our Services. By accessing or using any part of our Services, you agree to become bound by the terms and conditions of this agreement. If you do not agree to all the terms and conditions of this agreement, then you may not access or use any of our services.
                    </h5>
                    <h5 style={{ padding: "0 2.5em 2.5em 2.5em", textAlign: "left" }}>
                    1. You acknowledge that Heapdex and its Services may not operate correctly and may be substantially modified in future updates.
                    </h5>
                    <h5 style={{ padding: "0 2.5em 2.5em 2.5em", textAlign: "left" }}>
                    2. Our Services are provided “as is” without any warranty of any kind. Heapdex disclaims all warranties of any kind, express or implied, including, without limitation, the warranties of merchantability, fitness for a particular purpose and non-infringement. Heapdex does not make any warranty that our Services will be error free or that access thereto will be continuous or uninterrupted. You understand that you download from, or otherwise obtain content or services through, our Services at your own discretion and risk.
                    </h5>
                    <h5 style={{ padding: "0 2.5em 2.5em 2.5em", textAlign: "left" }}>
                    3. You represent and warrant that (i) your use of our Services will be in strict accordance with the Heapdex Privacy Policy, with this Agreement, and with all applicable laws and regulations (including without limitation any local laws or regulations in your country, state, city, or other governmental area, regarding online conduct and acceptable content, and including all applicable laws regarding the transmission of technical data exported from the United States or the country in which you reside) and (ii) your use of our Services will not infringe or misappropriate the intellectual property rights of any third party.
                    </h5>     
                    <h5 style={{ padding: "0 2.5em 2.5em 2.5em", textAlign: "left" }}>
                    4. We are constantly updating our Services, and that means sometimes we have to change the legal terms under which our Services are offered. If we make changes that are material, we will let you know by posting on one of our website or other communication before the changes take effect. The notice will designate a reasonable period of time after which the new terms will take effect. If you disagree with our changes, then you should stop using our Services within the designated notice period. Your continued use of our Services will be subject to the new terms. However, any dispute that arose before the changes shall be governed by the Terms (including the binding individual arbitration clause) that were in place when the dispute arose.
                    </h5>
                    <h5 style={{ padding: "0 2.5em 2.5em 2.5em", textAlign: "left" }}>
                    5. In no event will Heapdex be liable with respect to any subject matter of this Agreement under any contract, negligence, strict liability or other legal or equitable theory for: (i) any special, incidental or consequential damages; (ii) the cost of procurement for substitute products or services; (iii) for interruption of use or loss or corruption of data; or (iv) for any amounts that exceed the fees paid by you to Heapdex under this agreement during the twelve (12) month period prior to the cause of action. Heapdex shall have no liability for any failure or delay due to matters beyond their reasonable control. The foregoing shall not apply to the extent prohibited by applicable law.
                    </h5>
                    <h5 style={{ padding: "0 2.5em 2.5em 2.5em", textAlign: "left" }}>
                    6. You may not use the Service for any illegal or unauthorized purpose. You agree to comply with all laws, rules and regulations (for example, federal, state, local and provincial) applicable to your use of the Service and your Content (defined below), including but not limited to, copyright laws.
                    </h5>     
                    <h5 style={{ padding: "0 2.5em 0 2.5em", textAlign: "left" }}>
                    7. The Service contains content owned or licensed by Heapdex. Heapdex Content is protected by copyright, trademark, patent, trade secret and other laws, and, as between you and Heapdex, Heapdex owns and retains all rights in the Heapdex Content and the Service. You will not remove, alter or conceal any copyright, trademark, service mark or other proprietary rights notices incorporated in or accompanying the Heapdex Content and you will not reproduce, modify, adapt, prepare derivative works based on, perform, display, publish, distribute, transmit, broadcast, sell, license or otherwise exploit the Heapdex Content.
                    </h5>                    
                </header>
                <NavigationMenu handleCurrentPageState={this.handleCurrentPageState} />
            </div>
        );
    }
}
export default TermsOfService;