import React from 'react';
import 'antd/dist/antd.css';
import '../styles/style.css'
import NavigationMenu from './NavigationMenu.js';
import ScrollToTopOnMount from './ScrollToTopOnMount.js'

class PrivacyPolicy extends React.Component {

    state = {
        current: 'privacypolicy',
    }
    
    handleCurrentPageState = (e) => {
        if (e.key === this.state.current) {
            return;
        }

        this.props.history.push(`${e.key}`)
        document.body.scrollTop = 0;

    }

    render() {
        return (
            <div>
                <ScrollToTopOnMount/>
                <header className="privacyPolicy">
                    <h1>Privacy Policy</h1>
                    <h5 style={{ padding: "5vh 2.5em", textAlign: "left" }}>
                        Hey, potential user! If you become a Heapdex user, this privacy policy applies to you.
                    </h5>
                    <h5 style={{ padding: "0 2.5em 2.5em 2.5em", textAlign: "left" }}>
                        <span style={{ fontWeight:"300", fontSize:"24px" }}>Data Collected Automatically</span><br></br>
                        We use third-party services to support Heapdex’s functionality i.e. Firebase.<br></br>
                        When using Heapdex, Firebase may automatically collect the following data:<span style={{ fontWeight:"600", fontSize:"15px" }}> IP Address, Device ID</span><br></br>
                        The data is used for, but not limited to, log data, usage data, location information, etc.<br></br>
                        For more detailed information, see Firebase’s security and privacy information: <a style={{ fontWeight:"600", fontSize:"15px", color:"#3BB0E5" }}href="https://firebase.google.com/support/privacy/#examples_of_end-user_personal_data_processed_by_firebase">Firebase Privacy</a>
                    </h5>
                    <h5 style={{ padding: "0 2.5em 2.5em 2.5em", textAlign: "left" }}>
                        <span style={{ fontWeight:"300", fontSize:"24px" }}>How We Use Your Data</span><br></br>
                        1. Maintain and improve Heapdex<br></br>
                    </h5>
                    <h5 style={{ padding: "0 2.5em 2.5em 2.5em", textAlign: "left" }}>
                        <span style={{ fontWeight:"300", fontSize:"24px" }}>Your Privacy Rights</span><br></br>
                        1. You can choose not to use Heapdex<br></br>
                    </h5>             
                    <h5 style={{ padding: "0 2.5em 2.5em 2.5em", textAlign: "left" }}>
                        <span style={{ fontWeight:"300", fontSize:"24px" }}>Changes to Privacy Policy</span><br></br>
                        Thanks for reading. We know it’s dull but we want to make sure that your concerns on privacy are addressed as transparently as possible. We may make changes to this privacy policy in the future and, if changes are made, we will inform you of the changes before they go into effect. 
                    </h5>              
                </header>
                <NavigationMenu handleCurrentPageState={this.handleCurrentPageState} />
            </div>
        );
    }
}
export default PrivacyPolicy;